<!-- <p>public-navigation works! {{user()?.name}}</p> -->
<nav class="nav-public nav-main content content--center">
    <a [routerLink]="'/'" >
        <img class="logo" src="/assets/img/misc/logo-meldpunt.svg" alt="Meldpunt logo">
        <!-- <img class="show-collapsed" src="/assets/img/misc/logo-collapsed-meldpunt.svg" alt="Meldpunt logo"> -->
    </a>

    <div class="navigation-wrap">

        <div class="mobile-navigation d-flex align-items-center gap-16">
            <button (click)="toggleMobileNav()" class="hamburger increase-clickable-area">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
            </button>
        </div>
        

        @if (screenSize() === 'desktop') {
            <div class="desktop-navigation" appClickOutside (clickOutside)="closeNavigation()">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        }

    </div>

    @if (screenSize() !== 'desktop') {
        <div id="mobile-navigation-overlay">
            <div class="">
                <div class="nav-public nav-item d-flex justify-content-between align-items-center w-100">
                    <a [routerLink]="'/'">
                        <img class="logo" src="/assets/img/misc/logo-meldpunt--white.svg" alt="Meldpunt logo">
                    </a>
                    <button (click)="toggleMobileNav()" class="increase-clickable-area offset-bottom-10">
                        <i class="icon icon-close-x d-block"></i>
                    </button>
                </div>
           
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        </div>
    }
    
</nav>

<!-- routerLinkActive="active-link" -->
<ng-template #content>

    <!-- {{pages | json}} -->
    <ul id="navigation">
        <!-- get template? -->
        @for (page of pages; track page.titel) {

            @if (page.subpaginas) {
                @if (screenSize() === 'desktop') {
                    <li (click)="openSubnav($event)">
                        <button class="d-flex align-items-center nav-item gap-8 raise raise--hover">
                            <span>
                                {{page.titel}}
                            </span>
                            <i class="icon icon-chevron-up"></i>
                        </button>
                       <ul class="sub-navigation box-shadow radius-8">
                            @for (subpage of page.subpaginas; track $index) {
                                <li><a class="nav-item py-16" [routerLink]="[subpage.slug]" routerLinkActive="active-link">{{subpage.titel}}</a></li>
                            }
                       </ul>
                   </li>
                } @else{
                    @for (subpage of page.subpaginas; track subpage.slug) {
                        <li><a class="nav-item py-16" [routerLink]="[subpage.slug]" routerLinkActive="active-link">{{subpage.titel}}</a></li>
                    }
                }
            } @else {
                <li><a class="nav-item py-16" [routerLink]="[page.slug]" routerLinkActive="active-link">{{page.titel}}</a></li>
            }
           
        }
      
       @if (screenSize() === 'desktop') {
        <li class="mobile-button-float-bottom">
            <a [routerLink]="'aanmelden-medicijnpanel'" class="app-button primary-button--highlighted d-flex align-center gap-8 content content--center min-width-content">
                Aanmelden medicijnpanel
                <i class="icon icon-login--white icon-size--24"></i>
            </a>
        </li>
       }
   </ul>

   @if (screenSize() !== 'desktop') {
       <app-button 
           class="mobile-button-float-bottom"
           [text]="'Aanmelden medicijnpanel'"
           [icon]="'icon icon-login--white'"
           [iconSize]="'24px'"
           [classes]="'app-button primary-button--highlighted content content--center maxwidth-500'"
           (emitClick)="router.navigate(['aanmelden-medicijnpanel'])"
       ></app-button>
   }
</ng-template>
