import { inject, Injectable, signal } from '@angular/core';
import { Page } from 'backend/src/models/page';
import { lastValueFrom, map } from 'rxjs';
import { DefaultService } from 'src/app/utils/api/api/default.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    defaultService = inject(DefaultService);

    navigation = signal<Page[]>([]);

    public initNavigation() {
        return new Promise<void>(async (resolve) => {
            return await lastValueFrom(
                this.defaultService.pageGetPages().pipe(
                    map((next) => {
                        const fixedPages: Page[] = [
                            {
                                slug: 'nieuws',
                                titel: 'Nieuws'
                            },
                            {
                                slug: 'ervaring-zoeken',
                                titel: 'Ervaring zoeken'
                            }
                        ];
                        return [...fixedPages, ...next] as Page[];
                    })
                )
            ).then((pages) => {
                this.navigation.set(pages);
                resolve();
            });
        });
    }
}
