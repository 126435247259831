<div class="page page--public">
    <header>
        <!-- <div class="banner"></div> -->
        <app-image-banner [height]="bannerHeight + 'px'"></app-image-banner>
    </header>

    <section class="search-results" [style.padding-top.px]="bannerHeight">
        <div class="content content--center py-dynamic-large news-wrapper">
            <h1 class="page-title">Nieuws</h1>
           
            @if (ready) {
                <div class="cluster cluster--col-3 gap-dynamic-medium gap-48">
                    @for (item of news(); track item.slug) {
                        @if ($first) {
                            <article class="cluster-item newsitem newsitem-fullwidth cursor-pointer" [routerLink]="[item.slug]">
                                <div class="image-wrap">
                                    <img loading="lazy" [src]="item.afbeeldingURL" [alt]="item.titel" class="news-image radius-8 aspect-ratio aspect-ratio--3-2">
                                </div>
                                <div class="newsitem-content mt-8">
                                    <h2 class="title title--large">{{item.titel}}</h2>
                                    <div class="date">
                                        <i class="icon thumbnail-logo"></i>
                                        <span class="date">{{item.datum | formatTs : 'D MMMM YYYY'}}</span>
                                    </div>
                                    <div class="descr truncate">
                                        <span [innerHTML]="item.inhoud | safe : 'html'" class="richtext-print richtext-print--preview"></span>
                                    </div> 
    
                                    <a [routerLink]="[item.slug]" class="link">Lees meer</a>
                                </div>
                            </article>
                        } @else {
                            <article loading="lazy" class="cluster-item newsitem cursor-pointer" [routerLink]="[item.slug]">
                                <img class="news-image radius-8 aspect-ratio--3-2 object-fit--cover" [src]="item.afbeeldingURL" [alt]="item.titel">
                                <div class="newsitem-content h-100 mt-8">
                                    <div class="date">
                                        <i class="icon thumbnail-logo"></i>
                                        <span class="date">{{item.datum | formatTs : 'D MMMM YYYY'}}</span>
                                    </div>
                                    <h2 class="title title--large">{{item.titel}}</h2>
                                    <div class="descr truncate">
                                        <span [innerHTML]="item.inhoud | safe : 'html'" class="richtext-print richtext-print--preview"></span>
                                    </div> 
    
                                    <a [routerLink]="[item.slug]" class="link mt-auto">Lees meer</a>
                               </div>
                            </article>
                        }                   
                    }
                </div>
                @if (news()?.length < totalRows ) {
                    <button (click)="loadmore({startrow : (this.search$.value.startrow === 0 ? 7 : this.search$.value.startrow + 6), RPP : 6})" class="mt-24 loadmore app-button secondary-button" [class.saving]="loading" [attr.inert]="loading ? '' : null">
                        @if (loading) {
                            <app-inline-spinner></app-inline-spinner>
                        }
                        Toon meer nieuws
                    </button>
                }
            } @else {
                <div class="cluster cluster--col-3 gap-dynamic-medium gap-48">
                    @for (item of skeletonCount; track $index) {
                        @if ($first) {
                            <article class="cluster-item newsitem-fullwidth skeleton">
                                <div class="newsitem-content"></div>
                                <div class="image-wrap aspect-ratio aspect-ratio--3-2"></div>
                            </article>
                        } @else {
                            <article class="cluster-item newsitem skeleton">
                                <div class="news-image aspect-ratio--3-2"></div>
                                <div class="article-content mt-8"></div>
                            </article>
                        }                   
                    }
                </div>
            }


        </div>
    </section>
</div>