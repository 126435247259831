import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit, OnDestroy, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { DefaultService } from 'src/app/utils/api';
import { formatTsPipe } from '../../../../utils/pipes';
import { map, tap } from 'rxjs';

@Component({
    selector: 'app-medicine-detail',
    standalone: true,
    imports: [formatTsPipe, RouterModule],
    templateUrl: './medicine-detail.component.html',
    styleUrl: './medicine-detail.component.scss'
})
export class MedicineDetailComponent implements OnInit, OnDestroy {
    router = inject(Router);
    route = inject(ActivatedRoute);
    responsiveService = inject(ResponsiveService);
    defaultService = inject(DefaultService);

    public JSON = JSON;

    ready: boolean = false;

    medicijn = toSignal(
        this.defaultService
            .notificationGetNotificationDetail({
                slug: this.route.snapshot.paramMap.get('medicineSlug')
            })
            .pipe(
                tap((next) => {
                    this.ready = true;
                })
            ),
        {
            initialValue: undefined
        }
    );

    medicijnQueryParam: { medicijn: string } = undefined;

    screenSize = toSignal(this.responsiveService.onResize$, {
        initialValue: this.responsiveService.getScreenSize(window.innerWidth)
    });

    showOverlay: boolean = false;

    ngOnInit() {
        document.body.classList.add('noscroll');
    }

    dismiss() {
        this.router.navigate(['../../'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    }

    ngOnDestroy() {
        document.body.classList.remove('noscroll');
    }
}
