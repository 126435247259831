import { bootstrapApplication } from '@angular/platform-browser';
import { InMemoryScrollingOptions, provideRouter, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';

import { routes as publicRoutes } from './app/modules/public/public.routes';
import { APP_INITIALIZER, ApplicationConfig, mergeApplicationConfig } from '@angular/core';
import { AppComponent } from './app/app.component';
import { RouteWithConfig } from './app/shared/models/config.model';
import { InitService } from './app/shared/services/init.service';
import { appConfig } from './app/app.config';

fetch('/api/config')
    .then((res) => res.json())
    .then((config) => {
        let routes: RouteWithConfig[];
        routes = publicRoutes;
        let previousPath = window.location.pathname; // Initialize with the current path

        const maskConfig: Partial<IConfig> = {
            validation: false
        };

        function disableScrollingResoration(currentPath: string, previousPath: string): boolean {
            const currentRouteFirstSegment = currentPath === '/' ? '' : currentPath.match(/[^\/]+/g)?.[0];
            const previousRouteFirstSegment = previousPath === '/' ? '' : previousPath.match(/[^\/]+/g)?.[0];
            // get Current (parent) Route Object from Routes
            const currentRoute = routes.find(
                (route) =>
                    route.path === currentRouteFirstSegment ||
                    route.children?.some((childroute) => childroute.path === currentRouteFirstSegment)
            );
            // get Previous (parent) Route Object from Routes
            const previousRoute = routes.find(
                (route) =>
                    route.path === previousRouteFirstSegment ||
                    route.children?.some((childroute) => childroute.path === previousRouteFirstSegment)
            );

            // Check if Current route is parent/child of Previous route
            if (currentRoute?.path === previousRoute?.path) {
                //Check if route or childroute contains disableWithInMemoryScrolling key: true on
                if (currentRoute?.data?.config?.disableWithInMemoryScrolling) return true;
                if (currentRoute?.children?.some((childroute) => childroute.data?.config?.disableWithInMemoryScrolling))
                    return true;

                return false;
            }

            return false;
        }

        const scrollConfig: InMemoryScrollingOptions = {
            get scrollPositionRestoration() {
                // const params = new URLSearchParams(window.location.search);
                const currentPath = window.location.pathname;

                if (disableScrollingResoration(currentPath, previousPath)) {
                    return 'disabled' as const;
                }
                previousPath = currentPath;
                return 'enabled' as const; // Enable restoration for other cases
            },
            // scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled' // Enable anchor scrolling
        };

        const browserConfig: ApplicationConfig = {
            providers: [
                // routes
                provideRouter(routes, withViewTransitions(), withInMemoryScrolling(scrollConfig)),
                provideEnvironmentNgxMask(maskConfig),
                // custom
                { provide: 'config', useValue: config },
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: (initService: InitService) => {
                        return () => {
                            return initService.initialize(config);
                        };
                    },
                    deps: [InitService]
                },
                //provide animations to application
                provideAnimations()
            ]
        };

        // bootstrap the application 🚀
        bootstrapApplication(AppComponent, mergeApplicationConfig(appConfig, browserConfig)).catch((err) =>
            console.error(err)
        );
    });
