import { Component, inject, OnInit } from '@angular/core';
import { DefaultService } from 'src/app/utils/api';
import { SafePipe } from '../../../../utils/pipes';

@Component({
    selector: 'app-login-medicine-panel',
    standalone: true,
    imports: [SafePipe],
    templateUrl: './login-medicine-panel.component.html',
    styleUrl: './login-medicine-panel.component.scss'
})
export class LoginMedicinePanelComponent implements OnInit {
    defaultService = inject(DefaultService);

    title: string;
    content: string;
    ready: boolean = false;

    ngOnInit() {
        this.defaultService.pageGetPageDetail({ slug: 'aanmelden-medicijnpanel' }).subscribe((next) => {
            this.title = next.titel;
            this.content = next.inhoud;
            this.ready = true;
        });
    }
}
