import { JsonPipe } from '@angular/common';
import { Component, inject, Input, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type PopupPage = {
    knop?: {
        titel?: string;
        link?: string;
        nieuwTabblad?: boolean;
    };
    titel: string;
    inhoud: string;
    afbeeldingExt?: string;
    afbeeldingGrootte?: number;
    afbeeldingURL?: string;
};

@Component({
    selector: 'app-popup',
    standalone: true,
    imports: [JsonPipe],
    templateUrl: './popup.component.html',
    styleUrl: './popup.component.scss'
})
export class PopupComponent {
    activeModal = inject(NgbActiveModal);
    @Input() popup: PopupPage;
}
