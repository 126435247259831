<div class="field field-checkboxes d-flex flex-column {{classes}}" [class.hasOptionWidth]="!optionWidth || optionColumnWidth" [class.readOnly]="readOnly" [class.field-checkboxes--stacked]="stacked">
    @if (label) {
        <span [class.label-cursor]="labelIsClickable" (click)="labelIsClickable && selectAllCheckboxes()" class="field-label">{{label}}
            @if (optionalString) {
                <span>({{optionalString}})</span>
            }
        </span>
    }

    <div class="{{!optionWidth && optionColumnWidth ? 'row' : 'd-flex'}} options flex-wrap">
        @for (option of options; track $index) {
            <div class="col-{{colSize || 'md'}}-{{(!optionWidth && optionColumnWidth) || ''}} option-wrap mr-0" [ngStyle]="{'min-width': !optionColumnWidth && optionWidth ? (optionWidth) : 'auto'}" [class.flex-grow-1]="fillWidth">
                <div [class.error-field]="localError" (click)="(check(option.value))" [class.active]="isActive(option.value)" [class.disabled]="option.disabled || disabled" class="field-checkbox-option app-tooltip-wrap flex-grow-1 d-flex align-items-center">
                    <span class="fake-checkbox"></span> <span class="ellipsis option-title" [title]="option.title">{{option.title}}</span>
                    @if (option.tooltip) {
                        <div class="app-tooltip app-tooltip-default-centered">{{option.tooltip}}</div>
                    }
                </div>
            </div>
        }
    </div>
    @if (localError && showError) {
        <span class="error-field-display">{{localError}}</span>
    }
</div>