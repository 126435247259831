import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-inline-spinner',
    standalone: true,
    imports: [],
    templateUrl: './inline-spinner.component.html',
    styleUrl: './inline-spinner.component.scss'
})
export class InlineSpinnerComponent {
    @Input() spinnerSize = '16px';
    @Input() light: boolean = false;
}
