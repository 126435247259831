import { NgStyle } from '@angular/common';
import { Component, OnInit, OnChanges, SimpleChanges, Input, output } from '@angular/core';
export type RadioOption = { title: string; value: string; disabled?: boolean };

@Component({
    selector: 'app-field-radios',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './field-radios.component.html',
    styleUrl: './field-radios.component.scss'
})
export class FieldRadiosComponent implements OnChanges {
    @Input() options: RadioOption[];
    @Input() optionWidth: string;
    @Input() optionColumnWidth?: string;
    @Input() fixedWidth?: string;
    @Input() classes?: string = '';
    @Input() fillWidth?: boolean;
    @Input() tooltipObj?: { descr: { type: string; value: string }[]; title?: string } = null;

    @Input() canUncheck?: boolean = false;
    @Input() readOnly?: boolean;
    @Input() stacked: boolean = false;
    @Input() disabled: boolean = false;
    @Input() model: string | boolean | undefined;
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;

    modelChange = output<string>();
    emitBlur = output();

    localModel: string | boolean | undefined;
    localOptionalValueReadOnly: boolean;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.optionalInputModel || changes.type) {
            let preventEmit = true;
            if (changes.type && changes.type.previousValue) {
                preventEmit = false;
            }
        }

        if (changes.model) {
            this.localModel = this.model;
        }
    }

    emit($event: string) {
        if (this.readOnly) {
            return;
        }
        this.modelChange.emit($event);
    }
}
