import { Component, inject, Input, input, output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    merge,
    Observable,
    of,
    OperatorFunction,
    Subject,
    switchMap
} from 'rxjs';
import { FieldTextareaComponent } from '../fields/field-textarea/field-textarea.component';
import { DefaultService, Medicine, NotificationUpdateNotificationBody } from 'src/app/utils/api';
import { ButtonComponent } from '../misc/button/button.component';
import { FieldRadiosComponent, RadioOption } from '../fields/field-radios/field-radios.component';
import { FieldCheckboxesComponent } from '../fields/field-checkboxes/field-checkboxes.component';
import { FieldTypeaheadComponent } from '../fields/field-typeahead/field-typeahead.component';
import { FieldTextComponent } from '../fields/field-text/field-text.component';
import { FieldSingleCheckboxComponent } from '../fields/field-single-checkbox/field-single-checkbox.component';

export type Medicijnonderwerp = 'werking' | 'bijwerkingen' | 'praktisch';
export type MedicineForm = {
    medicijn: string;
    ervaringIndicator: number;
    ervaringDescr: string;
    meldingAangemaakt: boolean;
    meldingId?: string;
    onderwerp?: string[];
    leeftijd?: number;
    geslacht?: string;
    redenGebruik?: string;
    ervaringNadien?: NotificationUpdateNotificationBody.ErvaringNadienEnum;
    beslissing?: string;
    hulpverlener?: string;
    privacyPolicy?: boolean;

    // experience_subject?: string[];
    // age?: number;
    // gender?: string;
    // issue?: string;
    // decision?: string;
    // reasonUse?: string;
    // fabrikant?: string;
    // zorgverlener?: string;
    // privacy_verklaring?: string;
    meldingAangevuld?: boolean;
};

@Component({
    selector: 'app-medicine-form',
    standalone: true,
    imports: [
        FormsModule,
        NgbTypeahead,
        FieldTextareaComponent,
        FieldTextareaComponent,
        ButtonComponent,
        FieldRadiosComponent,
        FieldCheckboxesComponent,
        FieldTypeaheadComponent,
        FieldTextComponent,
        FieldSingleCheckboxComponent
    ],
    templateUrl: './medicine-form.component.html',
    styleUrl: './medicine-form.component.scss'
})
export class MedicineFormComponent {
    defaultService = inject(DefaultService);
    // @Input() medicijn: string;
    @Input() loading: boolean = false;
    @Input() FORM: MedicineForm = {
        medicijn: '',
        ervaringIndicator: undefined,
        ervaringDescr: undefined,
        meldingAangemaakt: false
    };

    FORMChange = output<MedicineForm>();

    validation = input<any>();
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    genderOptions: RadioOption[] = [
        {
            title: 'Man',
            value: 'M'
        },
        {
            title: 'Vrouw',
            value: 'F'
        }
    ];
    medicijnOnderwerpOptions: RadioOption[] = [
        {
            title: 'De werking',
            value: 'werking' as Medicijnonderwerp
        },
        {
            title: 'Bijwerkingen',
            value: 'bijwerkingen' as Medicijnonderwerp
        },
        {
            title: 'Praktische zaken (bv. verpakking, bijsluiter,...)',
            value: 'praktisch' as Medicijnonderwerp
        }
    ];
    decisionOptions: RadioOption[] = [
        {
            title: 'Doorgegaan met het gebruik',
            value: 'doorgegaan'
        },
        {
            title: 'Overgestapt',
            value: 'overgestapt'
        },
        {
            title: 'Gestopt',
            value: 'gestopt'
        },
        {
            title: 'Het medicijn anders gaan gebruiken',
            value: 'aangepast'
        }
    ];
    yesNoOptions: RadioOption[] = [
        {
            title: 'Ja',
            value: 'yes'
        },
        {
            title: 'Nee',
            value: 'no'
        }
    ];

    @ViewChild('medicineTypeaheadRef') medicineTypeaheadRef: NgbTypeahead;

    selectMedicijn(medicijn: string) {
        this.FORM.medicijn = medicijn;
        this.FORMChange.emit(this.FORM);
    }

    search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.medicineTypeaheadRef.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            switchMap((term) => {
                return term?.length && term?.length >= 2
                    ? this.defaultService
                          .medicineGetMedicines({ orderby: ['HPK desc'], rPP: 20, term: term })
                          .pipe(map((result) => result.data))
                    : of([]);
            }),
            map((experiences: Medicine[]) => experiences?.map((exp: Medicine) => exp.HPK)) // Map the result to titles
        );
    };

    selectExperience(score: number) {
        this.FORM.ervaringIndicator = score;
    }

    emojiAnimations: any[] = [
        {
            animatedSrc: '/assets/animations/experience_score-1.gif',
            staticSrc: '/assets/animations/experience_score-1.png',
            src: '/assets/animations/experience_score-1.png',
            duration: 930
        },
        {
            animatedSrc: '/assets/animations/experience_score-2.gif',
            staticSrc: '/assets/animations/experience_score-2.png',
            src: '/assets/animations/experience_score-2.png',
            duration: 930
        },
        {
            animatedSrc: '/assets/animations/experience_score-3.gif',
            staticSrc: '/assets/animations/experience_score-3.png',
            src: '/assets/animations/experience_score-3.png',
            duration: 930
        },
        {
            animatedSrc: '/assets/animations/experience_score-4.gif',
            staticSrc: '/assets/animations/experience_score-4.png',
            src: '/assets/animations/experience_score-4.png',
            duration: 930
        }
    ];

    playGif(index: number, duration: number) {
        // Set to animated GIF to play once
        this.emojiAnimations[index].src = this.emojiAnimations[index].animatedSrc;
        setTimeout(() => {
            this.emojiAnimations[index].src = this.emojiAnimations[index].staticSrc;
        }, duration);
    }
}
