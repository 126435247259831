import { CurrencyPipe, NgClass, NgStyle } from '@angular/common';
import {
    Component,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    Input,
    output,
    ElementRef,
    inject,
    computed,
    input
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { displayPipe } from '../../../../utils/pipes';
import { InlineSpinnerComponent } from '../../misc/inline-spinner/inline-spinner.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@Component({
    selector: 'app-field-text',
    standalone: true,
    imports: [FormsModule, NgClass, NgStyle, displayPipe, InlineSpinnerComponent, NgxMaskDirective],
    templateUrl: './field-text.component.html',
    styleUrl: './field-text.component.scss',
    providers: [CurrencyPipe]
})
export class FieldTextComponent implements AfterViewInit, OnChanges {
    currencyPipe = inject(CurrencyPipe);

    @Input() label?: string;
    @Input() error?: string;
    @Input() errorTooltip?: string;
    @Input() optionalString?: string;
    @Input() tooltipObj?: { descr: { type: string; value: string }[]; title?: string } = null;
    @Input() afterString?: string;
    // @Input() type?: 'integer' | 'float' | 'currency' | 'password' | 'email' | 'time' | '';
    type = input<'integer' | 'float' | 'currency' | 'password' | 'email' | 'time' | 'search' | ''>('');
    @Input() maxNumber?: number = undefined;
    @Input() toFixed?: number = null;
    @Input() typeClass?: string;
    @Input() placeholder?: string = '';
    @Input() mask?: string = '';
    @Input() thousandSeparator?: string = '';
    @Input() maskPrefix?: string = '';
    @Input() showMaskTyped: boolean = false;
    @Input() autocomplete: string = '';
    @Input() model: string | number;
    @Input() loading: boolean;
    @Input() hideValue: boolean;
    @Input() inputWidth: string;
    @Input() fixedWidth?: string;
    @Input() disabled: boolean = false;
    @Input() readonly: boolean = false;
    @Input() instant: boolean = false;
    @Input() focus: boolean = false;
    @Input() maxlength: number = 524288;
    @Input() classes: string;
    @Input() greyedOut: boolean = false;
    @Input() acceptedKeys: string[];
    @Input() showAsTag: boolean = false;
    @Input() showPressEnter: boolean = false;

    modelChange = output<string | number>();
    emitFocus = output();
    emitBlur = output();
    changed = output();
    enter = output();

    localModel: any;
    togglePwd: boolean = false;
    state: 'default' | 'blur' | 'focused' = 'default';

    dropSpecialCharacters = false;

    getType = computed(() =>
        this.type() && ((this.type() == 'password' && !this.togglePwd) || this.type() == 'email') ? this.type() : 'text'
    );

    @ViewChild('myinput') myInputField: ElementRef;
    ngAfterViewInit() {
        if (this.focus) {
            this.myInputField.nativeElement.focus();
        }
        if (this.thousandSeparator) {
            setTimeout(() => {
                this.dropSpecialCharacters = true;
            }, 1);
        }
    }

    onKey($event) {
        if (this.acceptedKeys && this.acceptedKeys.includes($event.key)) {
            this.localModel = this.localModel.substring(0, this.localModel.length - 1);
            this.modelChange.emit(this.localModel);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model || changes.type) {
            if (this.hideValue) {
                this.localModel = '';
                return;
            }
            let preventEmit = true;
            if (changes.type && changes.type.previousValue) {
                preventEmit = false;
            }
            if (this.type() == 'float') {
                this.localModel = this.model;
                setTimeout(() => {
                    this.formatFloat(preventEmit);
                }, 1);
            } else if (this.type() == 'currency') {
                this.localModel = this.model;
                setTimeout(() => {
                    this.formatCurrency(preventEmit);
                }, 1);
            } else if (this.type() == 'integer') {
                this.localModel = this.model;
                setTimeout(() => {
                    this.formatInteger(preventEmit);
                }, 1);
            }
            if (this.thousandSeparator) {
                this.localModel = this.model?.toString()?.replace('.', ',');
            } else {
                this.localModel = this.model;
            }
        }
    }

    changes() {
        if (this.instant) {
            setTimeout(() => {
                this.onBlur();
            }, 1);
        }
        this.changed.emit();
    }

    onBlur($event?) {
        if (this.type() == 'float') {
            this.formatFloat();
        } else if (this.type() == 'integer') {
            this.formatInteger();
        } else if (this.type() == 'currency') {
            this.formatCurrency();
        } else if (this.type() == 'time') {
            this.formatTime();
        } else {
            this.modelChange.emit(this.localModel || undefined);
        }
        this.emitBlur.emit();
    }

    formatTime(preventEmit?: boolean) {
        if (this.localModel == null || this.localModel == undefined) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        } else {
            if (this.localModel < 24 && this.localModel?.length <= 2) {
                if (this.localModel?.length !== 0) {
                    this.localModel = this.localModel + ':00';
                }
            }
            if (this.localModel?.length === 4) {
                this.localModel = '0' + this.localModel;
            }
            if (this.localModel?.length === 3) {
                this.localModel = '0' + this.localModel.substring(0, 2) + '0' + this.localModel.substring(2);
            }
            this.modelChange.emit(this.localModel);
        }
    }

    formatFloat(preventEmit?: boolean) {
        if (!this.localModel && this.localModel !== 0) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        }
        const numericValue = this.localModel.toString().replace(/,/g, '.');
        if (isNaN(parseFloat(numericValue))) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
        } else {
            this.localModel = this.twoDecimals(parseFloat(numericValue)).toString().replace(/\./g, ',');
            if (this.toFixed) {
                this.localModel = this.twoDecimals(parseFloat(numericValue)).toFixed(2).toString().replace(/\./g, ',');
            }
            if (!preventEmit) {
                this.modelChange.emit(this.twoDecimals(parseFloat(numericValue)));
            }
        }
    }

    formatCurrency(preventEmit?: boolean) {
        if (this.localModel == null || this.localModel == undefined) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        }
        let numericValue = this.localModel.toString().replace(/\./g, ',');
        const n = numericValue.lastIndexOf(',');
        if (n >= 0 && numericValue.length) {
            numericValue = numericValue.substring(0, n) + '.' + numericValue.substring(n + 1);
        }
        numericValue = numericValue.replace(/,/g, '');
        numericValue = numericValue.replace(/€/g, '');
        if (isNaN(parseFloat(numericValue))) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
        } else {
            let returnValue = this.currencyPipe
                .transform(parseFloat(numericValue).toString().replace(/,/g, '.'), '€', '€', '1.2-3')
                .replace(/,/g, '.')
                .replace(/€/g, '€ ');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            returnValue = returnValue.replace(/\u00A0/, '');
            this.localModel = returnValue;
            if (!preventEmit) {
                this.modelChange.emit(parseFloat(numericValue));
            }
        }
    }

    formatInteger(preventEmit?: boolean) {
        if (!this.localModel && this.localModel !== 0) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        }
        let numericValue = parseInt(this.localModel);
        if (isNaN(numericValue)) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
        } else {
            if (this.maxNumber !== undefined && numericValue > this.maxNumber) {
                numericValue = this.maxNumber;
            }
            this.localModel = numericValue;
            // setTimeout(() => {
            if (!preventEmit) {
                this.modelChange.emit(numericValue);
            }
            // }, 1);
        }
    }

    twoDecimals(number: number) {
        return Math.round(number * 100) / 100;
    }
}
