import { Component, inject, Input, OnInit } from '@angular/core';
import { ButtonComponent } from '../misc/button/button.component';
import { DefaultService } from 'src/app/utils/api';
import { Page } from 'backend/src/models/page';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RoutesRecognized } from '@angular/router';
import { filter, map } from 'rxjs';
import { NgStyle } from '@angular/common';

export type RouterLinkPage = Page & { fragment?: string };

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [ButtonComponent, RouterLink, NgStyle],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
    route = inject(ActivatedRoute);
    router = inject(Router);
    @Input() pages: RouterLinkPage[];
    splitHalf: number;
    bannerColor: string = '#FFFFFF';

    ngOnInit() {
        this.formatFooterPages();
        this.splitHalf = Math.ceil(this.pages?.length / 2);
        this.setBannerColor(this.route);

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.setBannerColor(this.route);
        });
    }

    private formatFooterPages() {
        const homePage: RouterLinkPage = {
            titel: 'Ervaring delen',
            slug: '',
            fragment: 'ervaring-delen'
        };

        const legalPages: RouterLinkPage[] = [
            {
                titel: 'Privacyverklaring',
                slug: 'privacybeleid'
            },
            {
                titel: 'Cookies',
                slug: 'cookies'
            }
        ];

        this.pages = [
            homePage,
            ...this.pages.reduce((pages: RouterLinkPage[], currentPage) => {
                if (currentPage.titel.toLocaleLowerCase() !== 'meer') {
                    pages.push(currentPage);
                }
                return pages;
            }, []),
            ...legalPages
        ];
    }

    private setBannerColor(route: ActivatedRoute): void {
        const routeData = this.getRouteData(route);

        if (routeData && routeData.footer && routeData.footer.bannerColor) {
            this.bannerColor = routeData.footer.bannerColor;
        } else {
            this.bannerColor = '#FFFFFF';
        }
    }

    private getRouteData(route: ActivatedRoute) {
        let childRoute = route.firstChild;
        while (childRoute && childRoute.firstChild) {
            childRoute = childRoute.firstChild;
        }
        return childRoute?.snapshot?.data?.config || null;
    }
}
