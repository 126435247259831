import { LeaveGuardService } from '../../shared/services/leave-guard.service';
import { RouteWithConfig } from '../../shared/models/config.model';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { NotFoundComponent } from '../../shared/components/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchErvaringenComponent } from './pages/search-ervaringen/search-ervaringen.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { MedicineDetailComponent } from './pages/medicine-detail/medicine-detail.component';
import { LoginMedicinePanelComponent } from './pages/login-medicine-panel/login-medicine-panel.component';
import { DynamicPageComponent } from './pages/dynamic-page/dynamic-page.component';

export const routes: RouteWithConfig[] = [
    {
        path: '',
        component: HomeComponent,
        canDeactivate: [LeaveGuardService],
        data: {
            config: {
                title: 'Meldpunt medicijnen | Bijwerkingen, tekorten en ervaringen',
                robots: 'index, follow',
                public: true,
                footer: {
                    bannerColor: '#FAF9FA'
                },
                disableWithInMemoryScrolling: true
            }
        },
        children: [
            {
                path: 'medicijn',
                redirectTo: ''
            },
            {
                path: 'medicijn/:medicineSlug',
                component: MedicineDetailComponent,
                canDeactivate: [LeaveGuardService],
                data: {
                    animation: 'slideIn',
                    config: {
                        title: 'async',
                        robots: 'index, follow',
                        public: true,
                        disableWithInMemoryScrolling: true
                    }
                }
            }
        ]
    },
    {
        path: 'ervaring-zoeken',
        component: SearchErvaringenComponent,
        canDeactivate: [LeaveGuardService],
        data: {
            config: {
                title: 'Meldpunt medicijnen | Zoek medicijn-ervaringen en bijwerkingen',
                robots: 'index, follow',
                public: true,
                disableWithInMemoryScrolling: true
            }
        },
        children: [
            {
                path: 'medicijn/:medicineSlug',
                component: MedicineDetailComponent,
                canDeactivate: [LeaveGuardService],
                data: {
                    animation: 'slideIn',
                    config: {
                        title: 'async',
                        robots: 'index, follow',
                        public: true,
                        disableWithInMemoryScrolling: true
                    }
                }
            }
        ]
    },
    {
        path: 'nieuws',
        canDeactivate: [LeaveGuardService],
        children: [
            {
                path: '',
                component: NewsComponent,
                canDeactivate: [LeaveGuardService],
                data: {
                    config: {
                        title: 'Meldpunt medicijnen | Nieuws en updates over medicijnen en gezondheid',
                        robots: 'index, follow',
                        public: true,
                        footer: {
                            bannerColor: '#FAF9FA'
                        },
                        disableWithInMemoryScrolling: true
                    }
                }
            },
            {
                path: ':slug',
                component: NewsDetailComponent,
                // component: DisclaimerComponent,
                canDeactivate: [LeaveGuardService],
                data: {
                    config: {
                        title: 'async',
                        robots: 'index, follow',
                        public: true,
                        footer: {
                            bannerColor: '#FAF9FA'
                        },
                        disableWithInMemoryScrolling: true
                    }
                }
            }
        ]
    },
    {
        path: 'aanmelden-medicijnpanel',
        component: LoginMedicinePanelComponent,
        canDeactivate: [LeaveGuardService],
        data: {
            config: {
                title: 'Aanmelden Medicijnpanel | Sluit u aan voor betere medicijnzorg',
                robots: 'index, follow',
                public: true,
                footer: {
                    bannerColor: '#FAF9FA'
                }
            }
        }
    },
    {
        path: ':pageSlug',
        component: DynamicPageComponent,
        canDeactivate: [LeaveGuardService],
        data: {
            config: {
                title: 'async',
                robots: 'index, follow',
                public: true,
                footer: {
                    bannerColor: '#FAF9FA'
                }
            }
        }
    },
    // 404
    {
        path: '**',
        component: NotFoundComponent,
        data: { config: { title: null, robots: 'noindex', public: true, hideNavigation: true, hideFooter: true } }
    }
];
