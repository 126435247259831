<div class="field field-radios d-flex flex-column {{classes}}" [class.hasOptionWidth]="!optionWidth || optionColumnWidth" [class.readOnly]="readOnly" [class.field-radios--stacked]="stacked">
    @if (label) {
        <span class="field-label">{{label}}
            @if (optionalString) {
                <span>({{optionalString}})</span>
            }
        </span>
    }
    <div class="{{!optionWidth && optionColumnWidth ? 'row' : 'd-flex'}} options flex-wrap">
        @for (option of options; track option.value) {
            <div class="col-lg-{{(!optionWidth && optionColumnWidth) || ''}}" [ngStyle]="{'min-width': !optionColumnWidth && optionWidth ? (optionWidth) : 'auto'}" [class.flex-grow-1]="fillWidth">
                <div [class.error-field]="error" [style.width]="fixedWidth ? fixedWidth : ''" (click)="(canUncheck && option.value == model) ? emit(undefined) : emit(option.value)" [class.active]="option.value === model" [class.disabled]="option.disabled || disabled" class="field-radio-option flex-grow-1 d-flex align-items-center">
                    <span class="fake-radio"></span> 
                    <span class="ellipsis option-title" [title]="option.title">{{option.title}}</span>
                </div>
            </div>
        }
    </div>
    @if (error && error !== 'true') {
        <span class="error-field-display">{{error}}</span>
    }
</div>