<section class="content content--small content--center py-48">   
    @if (ready) {
        @if (page()) {
            <div [innerHTML]="page().inhoud| safe : 'html'" class="richtext-print"></div>

            @if (page().slug === 'cookies') {
                <div class="cookie-settings">
                    <h2>Wijzig je voorkeur:</h2>
                    <div class="d-flex align-center gap-16">
                        <span class="p">{{cookiesStatus() === 'declined' ? 'Geweigerd' : 'Geaccepteerd'}}</span>
                        <button (click)="cookieBannerHidden.set(false)" class="link underline">Wijzig toestemming</button>
                    </div>
                </div>
            }
        } @else {
            <p class="p-24">Geen inhoud in deze pagina</p>
        }
    } @else {
        <app-spinner></app-spinner>
    }
</section>
