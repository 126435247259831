<div *ngIf="version" class="_version">{{version}}</div>

@if(ready) {
    <div class="app app--public screen-size--{{screenSize()}}">
        <app-public-navigation [pages]="navigationService.navigation()"></app-public-navigation>
        <!-- <app-public-navigation *ngIf="!configService.routeConfig().hideNavigation"></app-public-navigation> -->
        <main>
            <router-outlet></router-outlet>
        </main>
    </div>

    <app-footer [pages]="navigationService.navigation()"></app-footer>
    <!-- @switch (configService.serverConfig().portal) {
        @case ('public') {
            <div class="app app--public screen-size--{{screenSize()}}">
                <app-public-navigation *ngIf="!configService.routeConfig().hideNavigation"></app-public-navigation>
                <main>
                    <router-outlet></router-outlet>
                </main>
            </div>
        }       
    } -->
        <app-cookie-banner></app-cookie-banner>
}

