import { NgStyle } from '@angular/common';
import { Component, Input, output } from '@angular/core';

@Component({
    selector: 'app-button',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss'
})
export class ButtonComponent {
    @Input() disabled?: boolean = false;
    @Input() inputWidth?: string;
    @Input() submitting?: boolean = false;
    @Input() classes: string = 'primary-default';
    @Input() icon?: string = '';
    @Input() iconSize?: string = '16px';
    @Input() text: string = '';
    @Input() hrefLink: string = '';
    @Input() linkTarget: string = '';
    @Input() linkQueryParamsHandling: 'merge' | 'preserve' | '' = 'merge';

    emitClick = output();
    emitClickOptions = output();
}
