<div class="field field-select">
    @if (label) {
        <span class="field-label">
            {{label}}
            @if (optionalString) {
                <span>({{optionalString}})</span>
            }
        </span>
    }

    <select
        [ngModel]="model"
        [style.width]="fixedWidth ? fixedWidth : ''"
        [class.model-empty]="!model"
        [class.error-field]="error"
        [ngStyle]="{'max-width': maxWidth ? maxWidth : 'auto'}"
        [disabled]="disabled"
        [class.disabled]="disabled"
        (ngModelChange)="modelChange.emit($event)"
        class="{{classes}}"
        >
    
        @for (option of localOptions(); track option.value) {
            <option [ngValue]="option.value">{{option.title}}</option>
        }
    </select>

    @if (error) {
        <span class="error-field-display">{{error}}</span>
    }

</div>