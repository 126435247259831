import { Component, Input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { displayPipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-field-textarea',
    standalone: true,
    imports: [displayPipe, FormsModule],
    templateUrl: './field-textarea.component.html',
    styleUrl: './field-textarea.component.scss'
})
export class FieldTextareaComponent {
    @Input() label?: string = '';
    @Input() afterString?: string;
    @Input() tooltipObj?: { descr: { type: string; value: string }[]; title?: string } = null;

    @Input() optionalString?: string;
    @Input() error?: string;
    @Input() placeholder?: string = '';
    @Input() model: string = '';
    @Input() rows: number = 4;
    @Input() disabled: boolean = false;

    modelChange = output<string>();
    enter = output();

    modelChanged($event) {
        this.modelChange.emit($event.target.value);
    }
}
