<div class="page page--public">
    <header>
        <app-image-banner
            [height]="bannerHeight + 'px'"
        >
            <!-- <div class="content content--center position-relative pt-36 pb-36 cluster cluster--col-2 gap-48"> -->
            <div class="content content--center position-relative pt-36 pb-36 cluster header-colums gap-48">
                <div class="header-intro" [style.padding-top.px]="bannerHeight">
                    @if(loading?.page){
                        <div>
                            <span class="skeleton skeleton--paragraph"></span>
                            <span class="skeleton skeleton--paragraph"></span>
                            <span class="skeleton skeleton--paragraph"></span>
                        </div>
                    } @else {
                        <div [innerHTML]="page?.inhoud | safe : 'html'" class="richtext-print" ></div>
                    }

                </div>
                <div class="position-relative">
                    <div class="medicine-form-wrapper" [style.top.px]="-(bannerHeight / 3 * 2) - 36">
                        <app-medicine-form
                            [(FORM)]="FORM"
                            [validation]="validation"
                            [loading]="submitting"
                            >

                            @if (!FORM.meldingAangemaakt) {
                                <app-button [text]="'Deze ervaring delen'" (emitClick)="submitNotification()"></app-button>
                            } @else if (!FORM.meldingAangevuld) {
                                <app-button [text]="'Gegevens toevoegen aan mijn melding'" (emitClick)="submitAdditionalNotificationInfo()"></app-button>
                            } 
                            @if (FORM.meldingAangevuld) {
                                <div class="note note--success">
                                    <span>Hartelijk dank! We hebben je aanvullende gegevens goed ontvangen.</span>
                                </div>
    
                                <div class="note note--white d-flex flex-column gap-16">
                                    <span>Wilt u deze melding ook melden aan het Bijwerkingencentrum Lareb?</span>
                                    <a href="https://meldformulier.lareb.nl/Forms/ReportForm" target="_blank" class="app-button primary-button align-self-start d-flex align-center gap-8 text-decoration-none">Meld bij Lareb <i class="im-icon im-icon-link-external"></i></a>
                                </div>
                            }
                        </app-medicine-form>
                    </div>
                </div>
            </div>
        </app-image-banner>
    </header>

    <section class="background-color--lightgrey py-dynamic-large">
        <div class="content content--center">
            <div class="mb-48 d-flex justify-content-between flex-wrap gap-24">
                <h2 class="mb-0 h1">Recente medicijnervaringen</h2>
            </div>

            @if(ready){
                <div class="cluster cluster--col-3 gap-24">
                    @for (notification of recentNotifications(); track notification.slug) {
                        <article class="cluster-item box-shadow radius-8 gap-16 recent-medicine p-24 cursor-pointer" (click)="this.router.navigate(['medicijn', notification.slug])">
                            <div class="notification-header">
                                <i class="im-icon experience-icon" [style.background-image]="'url(/assets/img/icons/experience_score-' + notification.ervaringIndicator + '.svg)'"></i>
    
                                <div class="info">
                                    <span class="medicine-title">{{notification.medicijn}}</span>
                                    <span class="date">{{notification.createTS | formatTs : 'D MMMM YYYY'}}</span>
                                </div>
                            </div>
                            <div class="medicine-descr truncate truncate--7">
                                {{notification.ervaringDescr}}
                            </div>
                            <a [routerLink]="['medicijn',  notification.slug]" class="link mt-auto">Lees verder</a>
                        </article>
                    }
                </div>
                <button (click)="updateSearchNotifications({startrow: this.searchNotifications$.value.startrow + 6})" class="link link-center mt-24 loadmore" [class.saving]="loading.notifications" [attr.inert]="loading.notifications ? '' : null">
                    @if (loading.notifications) {
                        <app-inline-spinner></app-inline-spinner>
                    }
                    toon meer ervaringen
                </button>
            } @else {
                <div class="cluster cluster--col-3 gap-24">
                    @for (notification of recentNotificationsSkeletonCount; track $index) {
                        <article class="cluster-item box-shadow radius-8 gap-16 recent-medicine p-24 skeleton aspect-ratio--1"></article>
                    }
                </div>
            }
        </div>
    </section>
    <section class="py-dynamic-large">
        <div class="content content--center news-wrapper">
            <div class="d-flex justify-content-between flex-wrap gap-24">
                <h2 class="mb-0 h1">Nieuws</h2>
                <a [routerLink]="['nieuws']" class="link link--icon no-underline d-none d-md-flex">
                    <span>Alle nieuws</span>
                    <i class="im-icon im-icon-arrow-right"></i>
                </a>
            </div>

            @if(ready){
                <div class="cluster cluster--col-3 gap-dynamic-medium gap-48">
                    @for (article of news(); track $index) {
                        @if ($first) {
                            <article class="cluster-item newsitem newsitem--fullwidth cursor-pointer" [routerLink]="['nieuws', article.slug]">
                                <img loading="lazy" class="article-image radius-8 object-fit object-fit--3-2" [src]="article.afbeeldingURL" [alt]="article.titel">
                                <div class="article-content mt-8">
                                    <div class="date">
                                        <i class="icon thumbnail-logo"></i>
                                        <span class="date">{{article.datum | formatTs : 'D MMMM YYYY'}}</span>
                                    </div>
                                    <h3 class="title title--large">{{article.titel}}</h3>
                                    <div class="descr truncate">
                                        <span [innerHTML]="article.inhoud | safe : 'html'" class="richtext-print richtext-print--preview"></span>
                                    </div> 
    
                                    <a [routerLink]="['nieuws', article.slug]" class="link">Lees meer</a>
                                </div>
    
                            </article>
                        } @else {
                            <article class="cluster-item newsitem gap-8 cursor-pointer" [routerLink]="['nieuws', article.slug]">
                                <img loading="lazy" class="article-image radius-8  object-fit object-fit--3-2" [src]="article.afbeeldingURL" [alt]="article.titel">
                                <div class="article-content mt-8">
                                    <div class="date">
                                        <i class="icon thumbnail-logo"></i>
                                        <span class="date">{{article.datum | formatTs : 'D MMMM YYYY'}}</span>
                                    </div>
                                    <h3 class="title">{{article.titel}}</h3>
                                    <div class="descr truncate">
                                        <span [innerHTML]="article.inhoud | safe : 'html'" class="richtext-print richtext-print--preview"></span>
                                    </div> 
    
                                    <a [routerLink]="['nieuws', article.slug]" class="link mt-auto">Lees meer</a>
                                </div>
                            </article>
                        }
                    }
                </div>
                <a [routerLink]="['nieuws']" class="link link--icon no-underline d-flex d-md-none">
                    <span>Alle nieuws</span>
                    <i class="im-icon im-icon-arrow-right"></i>
                </a>
            } @else{
                <div class="cluster cluster--col-3 gap-dynamic-medium gap-48">
                    @for (article of newsSkeletonCount; track $index) {
                        @if ($first) {
                            <article class="cluster-item newsitem newsitem--fullwidth">
                                <div class="article-image radius-8 object-fit object-fit--3-2 skeleton"></div>
                                <div class="article-content mt-8">
                                    <div class="descr truncate">
                                        <span class="skeleton skeleton--paragraph"></span>
                                        <span class="skeleton skeleton--paragraph"></span>
                                        <span class="skeleton skeleton--paragraph"></span>
                                    </div> 
                                </div>

                            </article>
                        } @else {
                            <article class="cluster-item newsitem gap-8">
                                <span class="article-image radius-8 object-fit object-fit--3-2 skeleton"></span>
                                <div class="article-content mt-8">
                                    <div class="w-100">
                                        <span class="skeleton skeleton--paragraph"></span>
                                        <span class="skeleton skeleton--paragraph"></span>
                                        <span class="skeleton skeleton--paragraph"></span>
                                    </div>
                                </div>
                            </article>
                        }
                    }
                </div>
            }

        </div>
    </section>
    

    <!-- @else {
        <app-spinner></app-spinner>
    } -->
    
</div>

<div [@slideUp]="outlet.activatedRouteData?.animation">
    <router-outlet #outlet="outlet"></router-outlet>
</div>