import { NgStyle } from '@angular/common';
import { Component, Input, OnChanges, output, SimpleChanges } from '@angular/core';
export type CheckboxOption = { title: string; value: string; disabled?: boolean; tooltip?: string };

@Component({
    selector: 'app-field-checkboxes',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './field-checkboxes.component.html',
    styleUrl: './field-checkboxes.component.scss'
})
export class FieldCheckboxesComponent implements OnChanges {
    @Input() label?: string;
    @Input() model: string[];
    @Input() options: CheckboxOption[];
    @Input() classes: string;
    @Input() readOnly?: boolean;
    @Input() disabled: boolean = false;
    @Input() labelIsClickable: boolean = false;
    @Input() optionWidth: string;
    @Input() optionColumnWidth?: number;
    @Input() colSize?: string;
    @Input() fillWidth?: boolean;
    @Input() returnChecked: boolean = false;
    @Input() stacked?: boolean = false;
    @Input() error?: string;
    @Input() showError?: boolean = true;
    @Input() optionalString?: string;
    @Input() tooltipObj?: { descr: { type: string; value: string }[]; title?: string } = null;
    modelChange = output<string[]>();
    localError: string | String = '';

    ngOnChanges(changes: SimpleChanges) {
        if (changes.error) {
            if (typeof changes?.error?.currentValue === 'string' || changes?.error?.currentValue instanceof String) {
                this.localError = changes.error.currentValue;
            } else {
                this.localError = '';
            }
        }
    }

    emit($event) {
        if (this.readOnly) {
            return;
        }
        this.modelChange.emit($event);
    }

    check(value: string) {
        if (this.returnChecked) {
            this.emit(value);
            return;
        }
        let newModel = [...this.model];
        const index = newModel.indexOf(value);
        if (index != -1) {
            newModel.splice(index, 1);
        } else newModel.push(value);
        this.emit(newModel);
    }

    isActive(value: string) {
        return this.model && this.model.indexOf(value) != -1;
    }

    selectAllCheckboxes() {
        // let allOptions = [];

        // for (let i of this.options) {
        //     allOptions.push(i.value);
        // }

        this.modelChange.emit(
            this.options.reduce((newArr, curr) => {
                if (!curr.disabled) newArr.push(curr.value);
                return newArr;
            }, [])
        );
    }
}
