import { Component, Input, output } from '@angular/core';
import { displayPipe } from '../../../../utils/pipes';

@Component({
    selector: 'app-field-single-checkbox',
    standalone: true,
    imports: [displayPipe],
    templateUrl: './field-single-checkbox.component.html',
    styleUrl: './field-single-checkbox.component.scss'
})
export class FieldSingleCheckboxComponent {
    @Input() model: boolean = false;
    @Input() error: string;
    modelChange = output<boolean>();
}
