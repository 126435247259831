import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LeaveGuardService {
    canDeactivate(
        component: ICanExit,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return component && component.canExit ? component.canExit() : true;
    }
}

export interface ICanExit {
    canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}
