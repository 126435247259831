import { Directive, ElementRef, HostListener, inject, output } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
    elementRef = inject(ElementRef);
    clickOutside = output<void>();

    @HostListener('document:click', ['$event.target'])
    public onClick(target: any) {
        const clickedInside = this.elementRef.nativeElement.contains(target);
        if (!clickedInside) {
            this.clickOutside.emit();
        }
    }
}
