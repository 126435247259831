<div class="medicine-detail-wrapper" [class.medicine-detail-wrapper--mobile]="screenSize() === 'mobile'" [class.medicine-detail-wrapper--desktop]="screenSize() !== 'mobile'">
    <!-- close icon + skeleton -->

    @if(!ready) {
        <div class="medicine-header">            
            <i class="icon experience-score skeleton"></i>
            <div class="d-flex flex-column gap-8 pr-16 w-100">
                <div class="skeleton skeleton--paragraph mb-0"></div>
                <div class="date skeleton skeleton--paragraph mb-0"></div>
            </div>
            <button class="dismiss-icon" (click)="dismiss()">
                <i class="im-icon im-icon-x-light-2"></i>
            </button>
        </div>
        <div class="medicine-detail">
            <div class="medicine-content">
                <div class="skeleton skeleton--paragraph"></div>
                <div class="skeleton skeleton--paragraph"></div>
                <div class="skeleton skeleton--paragraph"></div>
            </div>
            
        </div>
    } @else {
        <div class="medicine-header">            
            <i class="icon experience-score experience-score--{{medicijn().ervaringIndicator}}"></i>
            <div class="d-flex flex-column gap-8 pr-16" [class.skeleton]="">
                <h2 class="mb-0">{{medicijn().medicijn}}</h2>
                <span class="date">{{medicijn().createTS | formatTs : 'D MMMM YYYY'}}</span>
            </div>
            <button class="dismiss-icon" (click)="dismiss()">
                <i class="im-icon im-icon-x-light-2"></i>
            </button>
        </div>
        <div class="medicine-detail">
            <div class="medicine-content">
                <p>{{medicijn().ervaringDescr}}</p>
            </div>
            <div class="medicine-links">
                <a [routerLink]="['/']" [queryParams]="{medicijn: medicijn().medicijn}" class="no-icon-hover">
                    <i class="icon icon-pencil"></i>
                    Schrijf zelf een ervaring over dit medicijn
                </a>
                
                <a [routerLink]="['/ervaring-zoeken']" queryParamsHandling="replace" [queryParams]="{search: JSON.stringify({medicijn: medicijn().medicijn})}" class="no-icon-hover">
                    <i class="icon icon-magnifying-glass"></i>
                    Zoek meer ervaringen op over dit medicijn
                </a>

                <a class="no-icon-hover" [attr.href]="'https://www.apotheek.nl'" target="_blank">
                    <i class="icon icon-info"></i>
                    Lees meer informatie over dit medicijn
                </a>
            </div>
            
            <a [routerLink]="['../../']" queryParamsHandling="preserve" class="link">Sluiten</a>
        </div>
    } 
    
   
</div>

<div class="overlay" (click)="dismiss()"></div>
