import { Injectable, inject } from '@angular/core';
import * as packageJson from '../../../../package.json';
import { ConfigService } from './config.service';
import 'dayjs/locale/nl-be';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    // injections
    configService = inject(ConfigService);

    getVersion(): string {
        if (['development', 'staging', 'acceptance'].includes(<string>this.configService.serverConfig().nodeEnv)) {
            return `${this.configService.serverConfig().nodeEnv} ${packageJson.version} - ${
                this.configService.serverConfig().uptime
            }`;
        } else return '';
    }

    getVersionNumber() {
        return packageJson.version;
    }

    setLanguage() {
        dayjs.locale('nl-be');
    }
}
