import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    // injections

    resources: Record<string, any[]> = {};

    public addResource(type: string, resource: any[]) {
        this.resources[type] = resource;
    }

    public getResource(type: string) {
        return this.resources[type];
    }

    public getResourceById(type: string, id: string) {
        const result = this.resources[type].find((item) => {
            return item.id === id;
        });
        return result || { title: 'not found', value: 'not found' };
    }

    public getResourceByCode(type: string, code: string) {
        const result = this.resources[type].find((item) => {
            return item?.code?.toLowerCase() === code?.toLowerCase();
        });
        return result || null;
    }
}

@Pipe({ name: 'resource', standalone: true })
export class resourcePipe implements PipeTransform {
    resourceService = inject(ResourceService);
    transform(value: any, type: string, identifier: 'code' | 'id'): any {
        if (value && identifier === 'code') {
            return this.resourceService.getResourceByCode(type, value);
        }
        if (value && identifier === 'id') {
            return this.resourceService.getResourceById(type, value);
        }
        return '-';
    }
}
