<div class="field field-text" [class.field-text--show-as-tag]="showAsTag" [ngClass]="typeClass">
    @if (label) {
        <span class="field-label" [class.greyed-out]="greyedOut">{{label}}
            @if (optionalString) {
                <span>({{optionalString}})</span>
            }
        </span>
    }

    <div class="d-flex align-items-center w-100 position-relative app-tooltip-wrap gap-8" [class.disabled-wrap]="disabled">
        <input
            [ngStyle]="{'max-width': inputWidth ? inputWidth : ''}"
            [style.width]="fixedWidth ? fixedWidth : ''" 
            #myinput
            [class.error-field]="error || errorTooltip"
            [type]="getType()"
            [attr.type]="type()"
            class="flex-grow-1 mw-32 {{classes}}"
            [(ngModel)]="localModel" 
            (ngModelChange)="changes()"
            (blur)="!instant && onBlur($event); state = 'default';"
            [dropSpecialCharacters]="dropSpecialCharacters"
            (focus)="emitFocus.emit(); state = 'focused';"
            (keyup.enter)="onBlur(null); enter.emit()"
            [placeholder]="placeholder"
            [readonly]="false"
            [prefix]="maskPrefix"
            [mask]="mask"
            [disabled]="disabled || greyedOut"
            [thousandSeparator]="thousandSeparator"
            [showMaskTyped]="showMaskTyped"
            [allowNegativeNumbers]="true"
            [autocomplete]="autocomplete"
            placeHolderCharacter=""
            [attr.inputmode]="(type() === 'integer' || type() === 'float') ? 'decimal' : 'text'" 
            [pattern]="(type() === 'currency') ? '/^\d+\.\d{1,3}$/' : ((type() === 'integer') ? '[0-9]*' : '')"
            [maxlength]="maxlength"
            [class.greyed-out]="greyedOut"
            (keyup)="onKey($event)"
        />
        @if (showAsTag && localModel) {
            <div class="show-as-tag">{{localModel}}</div>
        }
        @if (type() === 'password' && localModel) {
            <button (click)="togglePwd = !togglePwd" tabindex="-1" class="toggle-show">
                @if (togglePwd) {
                    <ng-container>{{'Verberg wachtwoord'}}</ng-container>
                } @else if (!togglePwd) {

                    <ng-container>{{'Toon wachtwoord'}}</ng-container>
                }
            </button>
        }
        @if (!loading && !disabled && showPressEnter && state === 'focused' && localModel?.length >= 1) {
            <span class="press--enter">
                druk <span class="fontweight-500">Enter</span>
                <i class="im-icon-enter"></i>
            </span>
        }
        @if (afterString) {
            <span class="after-string no-letter-spacing">{{afterString | display}}</span>
        }
        @if (errorTooltip) {
            <div class="app-tooltip app-tooltip-left-nowrap">{{errorTooltip}}</div>
        }
        @if (loading) {
            <div class="loading-wrap"><app-inline-spinner></app-inline-spinner></div>
        }
    </div>
    @if (error && error !== 'true') {
        <span class="error-field-display">{{error}}</span>
    }
</div>