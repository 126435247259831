import { JsonPipe, NgTemplateOutlet } from '@angular/common';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Medicine } from 'backend/src/models/medicine';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    merge,
    Observable,
    of,
    OperatorFunction,
    shareReplay,
    Subject,
    switchMap,
    tap
} from 'rxjs';
import { TypeaheadModel } from 'src/app/shared/components/fields/field-typeahead/field-typeahead.component';
import { ButtonComponent } from '../../../../../shared/components/misc/button/button.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { ClickOutsideDirective } from 'src/app/utils/directives';
import { DefaultService } from 'src/app/utils/api';
import { Page } from 'backend/src/models/page';

type DefaultSearchObject = {
    term?: string;
    startrow: number;
    RPP: number;
    orderby: { code: string; dir: string };
};

@Component({
    selector: 'app-public-navigation',
    standalone: true,
    imports: [
        RouterLink,
        FormsModule,
        NgbTypeahead,
        NgTemplateOutlet,
        ButtonComponent,
        JsonPipe,
        ClickOutsideDirective,
        RouterLinkActive
    ],
    templateUrl: './public-navigation.component.html',
    styleUrl: './public-navigation.component.scss'
})
export class PublicNavigationComponent {
    // authService = inject(AuthService);
    // user = this.authService.user;
    defaultService = inject(DefaultService);
    router = inject(Router);
    responsiveService = inject(ResponsiveService);
    screenSize = toSignal(this.responsiveService.onResize$, {
        initialValue: this.responsiveService.getScreenSize(window.innerWidth)
    });

    @Input() pages: Page[];

    loading: { page: boolean; search: boolean } = {
        page: true,
        search: true
    };

    constructor() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.closeNavigation();
            }
        });
    }

    closeNavigation() {
        const subnav = document.querySelector('.sub-navigation.open');
        const mobileNav = document.querySelector('#mobile-navigation-overlay.show');
        if (subnav) {
            const parentNode = subnav?.parentNode;
            //TODO : HOVER effect on nested!
            // (parentNode.previousSibling as Element).classList.remove('raise--active');
            // const test = parentNode.querySelector('.raise--active')?.classList.remove('raise--active');
            // console.log('test', test);
            subnav?.classList.remove('open');
            // parentNode.querySelector('.icon-chevron-up').classList.add('rotation-180');
        } else if (mobileNav) {
            mobileNav.classList.remove('show');
        }
    }

    toggleMobileNav() {
        const open = document.querySelector('#mobile-navigation-overlay').classList.toggle('show');
    }

    openSubnav(targetElement: MouseEvent) {
        const parentNode = (targetElement.target as HTMLElement).parentNode.parentNode as Element;
        const button = (targetElement.target as HTMLElement).parentNode as Element;
        const subnav = parentNode?.querySelector('.sub-navigation');

        button?.classList.toggle('raise--active');
        subnav?.classList.toggle('open');

        if (subnav?.classList.contains('open')) {
            // subnav?.classList.toggle('open');
            // parentNode?.querySelector('.icon-chevron-up')?.classList.remove('rotation-180');
        } else {
            document.querySelector('.sub-navigation')?.classList.remove('open');
            // subnav?.classList.toggle('open');
            // parentNode?.querySelector('.icon-chevron-up')?.classList.add('rotation-180');
        }
    }
}
