import { Injectable, computed, signal } from '@angular/core';
import { ServerConfig, RouteConfig } from '../models/config.model';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    // signals
    routeConfig = signal<RouteConfig>(null);
    serverConfig = signal<ServerConfig>(null);

    cookieBannerHidden = signal(false);
    cookiesStatus = signal<'accepted' | 'declined'>(null);
}
