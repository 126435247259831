import { Component, inject, OnInit } from '@angular/core';
import { SafePipe } from '../../../../utils/pipes';
import { DefaultService } from 'src/app/utils/api';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerComponent } from '../../../../shared/components/misc/spinner/spinner.component';
import { BehaviorSubject, map, shareReplay, switchMap, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { ConfigService } from 'src/app/shared/services/config.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { Title } from '@angular/platform-browser';
import { RoutingService } from 'src/app/shared/services/routing.service';

@Component({
    selector: 'app-dynamic-page',
    standalone: true,
    imports: [SafePipe, SpinnerComponent],
    templateUrl: './dynamic-page.component.html',
    styleUrl: './dynamic-page.component.scss'
})
export class DynamicPageComponent {
    defaultService = inject(DefaultService);
    titleService = inject(Title);
    routingService = inject(RoutingService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    cookiesStatus = inject(ConfigService).cookiesStatus;
    cookieBannerHidden = inject(ConfigService).cookieBannerHidden;
    navigation = inject(NavigationService).navigation();

    private slug$ = this.route.params.pipe(
        map((p) => p['pageSlug']),
        tap((slug) => {
            const pageTitle = this.navigation.reduce((selected, current) => {
                if (current.slug === slug) selected = current;
                else {
                    const page = current.subpaginas?.find((subpage) => subpage.slug === slug);
                    if (page) selected = page;
                }

                return selected;
            })?.titel;
            if (pageTitle) this.titleService.setTitle(`${this.routingService.appTitle} | ${pageTitle}`);
        }),
        shareReplay()
    );

    private page$ = this.slug$.pipe(
        tap(() => {
            this.ready = false;
        }),
        switchMap((slug) => this.defaultService.pageGetPageDetail({ slug: slug })),
        tap(() => {
            this.ready = true;
        }),
        shareReplay(1)
    );

    ready: boolean = false;
    page = toSignal(this.page$, { initialValue: undefined });
}
