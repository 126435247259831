import { NgStyle } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';

@Component({
    selector: 'app-image-banner',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './image-banner.component.html',
    styleUrl: './image-banner.component.scss'
})
export class ImageBannerComponent {
    @Input() backgroundImage?: string = 'assets/img/misc/ivm-header-image.webp';
    @Input() height: string = '150px';
}
