<div class="field field-textarea">
    @if(label){
        <span class="field-label" [class.disabled]="disabled">
            {{label}}
            @if (optionalString) {
                <span>({{optionalString}})</span>
            }
        </span>
    }
    <textarea 
        [class.error-field]="error"
        [(ngModel)]="model" 
        (input)="modelChanged($event)"
        (keyup.enter)="enter.emit()"
        [rows]="rows"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [class.disabled]="disabled"
    ></textarea>
    @if (afterString) {
        <span class="after-textarea passive-gray">{{afterString | display}}</span>
    }
    @if (error) {
        <span class="error-field-display">{{error}}</span>
    }
</div>