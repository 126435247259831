<section class="content-padding">

    <div class="content content--small content--center">
        <h1>{{title}}</h1>
        <div [innerHTML]="content | safe : 'html'" class="richtext-print"></div>
    </div>

    <div class="content content--center pt-48">
        <iframe src="https://panelsignup.enalyzer.com/customers/IVM/register/" frameborder="0" width="100%" height="2650px"></iframe>
    </div>

</section>