import { JsonPipe, NgStyle } from '@angular/common';
import { JsonpInterceptor } from '@angular/common/http';
import { Component, Input, output, OnInit, model, computed, input } from '@angular/core';
import { FormsModule } from '@angular/forms';

export type SelectOption = { title: string; value: string };

@Component({
    selector: 'app-field-select',
    standalone: true,
    imports: [FormsModule, NgStyle, JsonPipe],
    templateUrl: './field-select.component.html',
    styleUrl: './field-select.component.scss'
})
export class FieldSelectComponent {
    options = input.required<SelectOption[]>();
    @Input() model: string;
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() fixedWidth?: string;
    @Input() maxWidth?: string;
    @Input() disabled?: string;
    @Input() classes?: string;

    localOptions = computed(() => {
        return this.placeholder ? [{ title: this.placeholder, value: undefined }, ...this.options()] : this.options();
    });

    modelChange = output<string>();
}
