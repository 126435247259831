import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageBannerComponent } from 'src/app/shared/components/banners/image-banner/image-banner.component';
import { DefaultService } from 'src/app/utils/api';
import { formatTsPipe, SafePipe } from '../../../../utils/pipes';
import { SpinnerComponent } from '../../../../shared/components/misc/spinner/spinner.component';

interface NewsPage {
    slug?: string;
    titel: string;
    inhoud: string;
    sequence: number;
    afbeeldingURL?: string;
    afbeeldingExt?: string;
    afbeeldingGrootte?: number;
    datum: string;
}

@Component({
    selector: 'app-news-detail',
    standalone: true,
    imports: [ImageBannerComponent, formatTsPipe, SafePipe, SpinnerComponent],
    templateUrl: './news-detail.component.html',
    styleUrl: './news-detail.component.scss'
})
export class NewsDetailComponent implements OnInit {
    defaultService = inject(DefaultService);
    route = inject(ActivatedRoute);
    bannerHeight: number = 150;
    page: NewsPage;
    ready: boolean = false;

    ngOnInit() {
        //slug
        this.defaultService
            .newsitemGetNewsitemDetail({ slug: this.route.snapshot.paramMap.get('slug') })
            .subscribe((next) => {
                this.page = next;
                this.ready = true;
            });
    }
}
